import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import { Content } from 'types'
import { Contact } from 'features'

const Index = ({
  appShellData,
}: {
  appShellData: AppShellDataProps<Content>
}) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <Contact />
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
